import { SVGProps } from 'react';

const SvgHomepageQr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={200}
    viewBox="0 0 200 200"
    {...props}
  >
    <rect
      shapeRendering="optimizeSpeed"
      x={0}
      y={0}
      width={200}
      height={200}
      fill="white"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={12}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={19}
      y={12}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={26}
      y={12}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={33}
      y={12}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={40}
      y={12}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={47}
      y={12}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={54}
      y={12}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={68}
      y={12}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={75}
      y={12}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={82}
      y={12}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={89}
      y={12}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={110}
      y={12}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={138}
      y={12}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={145}
      y={12}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={152}
      y={12}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={159}
      y={12}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={166}
      y={12}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={173}
      y={12}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={180}
      y={12}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={19}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={54}
      y={19}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={89}
      y={19}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={96}
      y={19}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={138}
      y={19}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={180}
      y={19}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={26}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={26}
      y={26}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={33}
      y={26}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={40}
      y={26}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={54}
      y={26}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={75}
      y={26}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={89}
      y={26}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={103}
      y={26}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={110}
      y={26}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={117}
      y={26}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={124}
      y={26}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={138}
      y={26}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={152}
      y={26}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={159}
      y={26}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={166}
      y={26}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={180}
      y={26}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={33}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={26}
      y={33}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={33}
      y={33}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={40}
      y={33}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={54}
      y={33}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={82}
      y={33}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={89}
      y={33}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={103}
      y={33}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={117}
      y={33}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={124}
      y={33}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={138}
      y={33}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={152}
      y={33}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={159}
      y={33}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={166}
      y={33}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={180}
      y={33}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={40}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={26}
      y={40}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={33}
      y={40}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={40}
      y={40}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={54}
      y={40}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={82}
      y={40}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={89}
      y={40}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={96}
      y={40}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={103}
      y={40}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={110}
      y={40}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={124}
      y={40}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={138}
      y={40}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={152}
      y={40}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={159}
      y={40}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={166}
      y={40}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={180}
      y={40}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={47}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={54}
      y={47}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={75}
      y={47}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={89}
      y={47}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={110}
      y={47}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={138}
      y={47}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={180}
      y={47}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={54}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={19}
      y={54}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={26}
      y={54}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={33}
      y={54}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={40}
      y={54}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={47}
      y={54}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={54}
      y={54}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={68}
      y={54}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={82}
      y={54}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={96}
      y={54}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={110}
      y={54}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={124}
      y={54}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={138}
      y={54}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={145}
      y={54}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={152}
      y={54}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={159}
      y={54}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={166}
      y={54}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={173}
      y={54}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={180}
      y={54}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={68}
      y={61}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={75}
      y={61}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={96}
      y={61}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={103}
      y={61}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={117}
      y={61}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={124}
      y={61}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={68}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={19}
      y={68}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={33}
      y={68}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={40}
      y={68}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={54}
      y={68}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={75}
      y={68}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={96}
      y={68}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={110}
      y={68}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={124}
      y={68}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={138}
      y={68}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={180}
      y={68}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={75}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={47}
      y={75}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={61}
      y={75}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={82}
      y={75}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={110}
      y={75}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={117}
      y={75}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={124}
      y={75}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={145}
      y={75}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={152}
      y={75}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={159}
      y={75}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={166}
      y={75}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={173}
      y={75}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={19}
      y={82}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={26}
      y={82}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={40}
      y={82}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={54}
      y={82}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={61}
      y={82}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={68}
      y={82}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={75}
      y={82}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={96}
      y={82}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={117}
      y={82}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={159}
      y={82}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={180}
      y={82}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={89}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={19}
      y={89}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={33}
      y={89}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={47}
      y={89}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={61}
      y={89}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={68}
      y={89}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={110}
      y={89}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={117}
      y={89}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={131}
      y={89}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={138}
      y={89}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={159}
      y={89}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={166}
      y={89}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={173}
      y={89}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={180}
      y={89}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={96}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={33}
      y={96}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={47}
      y={96}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={54}
      y={96}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={68}
      y={96}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={75}
      y={96}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={82}
      y={96}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={124}
      y={96}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={138}
      y={96}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={145}
      y={96}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={180}
      y={96}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={103}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={19}
      y={103}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={33}
      y={103}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={40}
      y={103}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={61}
      y={103}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={68}
      y={103}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={75}
      y={103}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={82}
      y={103}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={96}
      y={103}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={103}
      y={103}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={117}
      y={103}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={124}
      y={103}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={152}
      y={103}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={173}
      y={103}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={110}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={19}
      y={110}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={40}
      y={110}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={54}
      y={110}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={61}
      y={110}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={75}
      y={110}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={82}
      y={110}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={103}
      y={110}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={117}
      y={110}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={131}
      y={110}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={152}
      y={110}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={159}
      y={110}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={166}
      y={110}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={173}
      y={110}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={180}
      y={110}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={117}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={33}
      y={117}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={61}
      y={117}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={68}
      y={117}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={75}
      y={117}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={96}
      y={117}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={110}
      y={117}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={124}
      y={117}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={131}
      y={117}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={138}
      y={117}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={145}
      y={117}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={159}
      y={117}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={166}
      y={117}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={180}
      y={117}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={124}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={26}
      y={124}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={40}
      y={124}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={54}
      y={124}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={61}
      y={124}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={68}
      y={124}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={75}
      y={124}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={82}
      y={124}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={89}
      y={124}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={96}
      y={124}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={103}
      y={124}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={124}
      y={124}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={131}
      y={124}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={138}
      y={124}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={145}
      y={124}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={152}
      y={124}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={166}
      y={124}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={173}
      y={124}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={68}
      y={131}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={82}
      y={131}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={89}
      y={131}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={124}
      y={131}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={152}
      y={131}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={166}
      y={131}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={173}
      y={131}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={138}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={19}
      y={138}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={26}
      y={138}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={33}
      y={138}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={40}
      y={138}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={47}
      y={138}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={54}
      y={138}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={82}
      y={138}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={96}
      y={138}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={103}
      y={138}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={110}
      y={138}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={124}
      y={138}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={138}
      y={138}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={152}
      y={138}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={180}
      y={138}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={145}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={54}
      y={145}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={75}
      y={145}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={89}
      y={145}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={103}
      y={145}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={117}
      y={145}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={124}
      y={145}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={152}
      y={145}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={180}
      y={145}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={152}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={26}
      y={152}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={33}
      y={152}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={40}
      y={152}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={54}
      y={152}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={68}
      y={152}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={89}
      y={152}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={96}
      y={152}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={110}
      y={152}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={117}
      y={152}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={124}
      y={152}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={131}
      y={152}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={138}
      y={152}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={145}
      y={152}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={152}
      y={152}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={159}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={26}
      y={159}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={33}
      y={159}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={40}
      y={159}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={54}
      y={159}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={68}
      y={159}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={82}
      y={159}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={96}
      y={159}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={110}
      y={159}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={131}
      y={159}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={138}
      y={159}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={173}
      y={159}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={180}
      y={159}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={166}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={26}
      y={166}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={33}
      y={166}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={40}
      y={166}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={54}
      y={166}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={75}
      y={166}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={89}
      y={166}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={103}
      y={166}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={124}
      y={166}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={152}
      y={166}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={159}
      y={166}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={166}
      y={166}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={173}
      y={166}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={180}
      y={166}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={173}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={54}
      y={173}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={68}
      y={173}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={75}
      y={173}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={82}
      y={173}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={89}
      y={173}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={110}
      y={173}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={131}
      y={173}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={138}
      y={173}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={145}
      y={173}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={152}
      y={173}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={166}
      y={173}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={173}
      y={173}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={180}
      y={173}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={12}
      y={180}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={19}
      y={180}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={26}
      y={180}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={33}
      y={180}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={40}
      y={180}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={47}
      y={180}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={54}
      y={180}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={68}
      y={180}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={75}
      y={180}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={89}
      y={180}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={96}
      y={180}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={117}
      y={180}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={124}
      y={180}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={131}
      y={180}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={138}
      y={180}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={159}
      y={180}
      width={7}
      height={7}
      fill="black"
    />
    <rect
      shapeRendering="optimizeSpeed"
      x={180}
      y={180}
      width={7}
      height={7}
      fill="black"
    />
  </svg>
);

export default SvgHomepageQr;
