import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export interface BgmController {
  isPlaying: boolean;
  unlockAndPlay: () => void;
  pause: () => void;
}

const audioActivateEvents = ['keydown', 'click', 'touchstart', 'touchend'];

function useBgm(audioRef: MutableRefObject<HTMLAudioElement>): BgmController {
  const [isPlaying, setPlaying] = useState(false);
  const audioContext = useRef<AudioContext>();
  const gainNode = useRef<GainNode>();

  const play = useCallback(() => {
    if (!audioRef.current || !gainNode.current?.gain || !audioContext.current) {
      return;
    }
    audioRef.current.play();
    gainNode.current.gain.setTargetAtTime(
      1.0,
      audioContext.current.currentTime + 1,
      0.5,
    );
    navigator.mediaSession.playbackState = 'playing';

    setPlaying(true);
  }, [audioRef, audioContext, gainNode]);

  const pause = useCallback(() => {
    setPlaying(false);
    gainNode.current.gain.setTargetAtTime(
      0.01,
      audioContext.current.currentTime + 1,
      0.5,
    );
    navigator.mediaSession.playbackState = 'paused';

    setTimeout(() => {
      audioRef.current?.pause();
    }, 2000);
  }, [audioRef, audioContext, gainNode]);

  const unlockAndPlay = useCallback(() => {
    if (!audioContext.current) {
      audioContext.current = new (window.AudioContext
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        || window.webkitAudioContext)();

      const initialize = () => {
        audioContext.current.resume();
        const source = audioContext.current.createMediaElementSource(
          audioRef.current,
        );

        const newGainNode = audioContext.current.createGain();
        newGainNode.gain.value = 0;
        newGainNode.connect(audioContext.current.destination);
        source.connect(newGainNode);
        gainNode.current = newGainNode;

        audioActivateEvents.forEach((eventName) => {
          window.removeEventListener(eventName, unlockAndPlay);
        });

        if ('mediaSession' in navigator) {
          navigator.mediaSession.metadata = new MediaMetadata({
            title: 'BLIMP',
            artist: '@blimp.travel',
            album: '세상의 모든 안식처로 당신을 안내합니다',
            artwork: [
              {
                src: '/albumart/share.thumbnail@96w.png',
                sizes: '96x96',
                type: 'image/png',
              },
              {
                src: '/albumart/share.thumbnail@128w.png',
                sizes: '128x128',
                type: 'image/png',
              },
              {
                src: '/albumart/share.thumbnail@192w.png',
                sizes: '192x192',
                type: 'image/png',
              },
              {
                src: '/albumart/share.thumbnail@256w.png',
                sizes: '256x256',
                type: 'image/png',
              },
              {
                src: '/albumart/share.thumbnail@384w.png',
                sizes: '384x384',
                type: 'image/png',
              },
              {
                src: '/albumart/share.thumbnail@512w.png',
                sizes: '512x512',
                type: 'image/png',
              },
            ],
          });

          navigator.mediaSession.setActionHandler('play', play);
          navigator.mediaSession.setActionHandler('pause', pause);
          navigator.mediaSession.setActionHandler('stop', pause);
        }
        play();
      };

      if (navigator.userAgent.toLowerCase().includes('android')) {
        setTimeout(() => {
          initialize();
        }, 1000);
      } else {
        initialize();
      }
    } else {
      play();
    }
  }, [play, pause, audioRef]);

  const removeEventListeners = useCallback(() => {
    audioActivateEvents.forEach((eventName) => {
      window.removeEventListener(eventName, unlockAndPlay);
    });
  }, [unlockAndPlay]);

  useEffect(() => {
    const addEventListeners = () => {
      audioActivateEvents.forEach((eventName) => {
        window.addEventListener(eventName, unlockAndPlay, false);
      });
    };

    addEventListeners();
    return () => {
      removeEventListeners();
    };
  }, [removeEventListeners, unlockAndPlay]);

  return {
    isPlaying,
    unlockAndPlay,
    pause,
  };
}

export default useBgm;
