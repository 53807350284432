import { motion } from 'framer-motion';
import React from 'react';
import { Box, SxStyleProp } from 'rebass/styled-components';

interface FadeInTextProps {
  duration?: number;
  delay?: number;
  from?: number;
  to?: number;
  overflow?: string;
  isVisible?: boolean;
  sx?: SxStyleProp;
}

const FadeInText: React.FC<React.PropsWithChildren<FadeInTextProps>> = ({
  children,
  duration = 1,
  delay = 0,
  from = 100,
  to = 0,
  overflow = 'hidden',
  isVisible = false,
  sx,
}) => (
  <Box sx={{ ...sx, overflow }}>
    <motion.div
      initial="hidden"
      animate={isVisible ? 'visible' : 'hidden'}
      variants={{
        visible: {
          y: `${to}%`,
          opacity: 1,
          transition: {
            ease: [0, 0.55, 0.45, 1],
            duration,
            delay,
          },
        },
        hidden: {
          y: `${from}%`,
          opacity: overflow === 'visible' ? 0 : 1,
          transition: {
            duration: 0,
            delay: 1,
          },
        },
      }}
    >
      {children}
    </motion.div>
  </Box>
);

export default FadeInText;
