import { SVGProps } from 'react';

const SvgInstagram = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={56}
    height={56}
    viewBox="0 0 73 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={36.5}
      cy={36.5}
      r={36.5}
      fill="#525854"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.503 51.6667H31.4964C25.7087 51.6667 21 47.0088 21 41.2837V31.3829C21 25.6579 25.7087 21 31.4964 21H42.503C48.2906 21 52.9994 25.6579 52.9994 31.3829V41.2837C53 47.0088 48.2913 51.6667 42.503 51.6667ZM31.4964 22.6073C26.6044 22.6073 22.6248 26.5438 22.6248 31.3829V41.2837C22.6248 46.1229 26.6044 50.0594 31.4964 50.0594H42.503C47.395 50.0594 51.3745 46.1229 51.3745 41.2837V31.3829C51.3745 26.5438 47.395 22.6073 42.503 22.6073H31.4964ZM36.9998 44.5336C32.746 44.5336 29.2852 41.1101 29.2852 36.9023C29.2852 32.6945 32.746 29.271 36.9998 29.271C41.2536 29.271 44.7145 32.6945 44.7145 36.9023C44.7145 41.1101 41.2536 44.5336 36.9998 44.5336ZM36.9998 30.8783C33.6416 30.8783 30.91 33.5804 30.91 36.9023C30.91 40.2242 33.6416 42.9263 36.9998 42.9263C40.358 42.9263 43.0897 40.2242 43.0897 36.9023C43.0897 33.5804 40.3574 30.8783 36.9998 30.8783ZM43.6723 28.8858H46.7666C47.2151 28.8858 47.579 28.5258 47.579 28.0822C47.579 27.6386 47.2151 27.2786 46.7666 27.2786H43.6723C43.2238 27.2786 42.8599 27.6386 42.8599 28.0822C42.8599 28.5258 43.2238 28.8858 43.6723 28.8858Z"
      fill="white"
    />
  </svg>
);

export default SvgInstagram;
