import React, { useMemo } from 'react';
import { Box, SxStyleProp } from 'rebass/styled-components';

interface ResponsiveBuilderProps {
  sx?: SxStyleProp;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
}

const ResponsiveBuilder: React.FC<React.PropsWithChildren<ResponsiveBuilderProps>> = ({
  sx,
  small = false,
  medium = false,
  large = false,
  children,
}) => {
  const display: string[] = useMemo(
    () => [
      small ? 'block' : 'none',
      medium ? 'block' : 'none',
      large ? 'block' : 'none',
    ],
    [small, medium, large],
  );

  return (
    <Box sx={{ ...sx, display }}>
      <>
        {children}
      </>
    </Box>
  );
};

export default ResponsiveBuilder;
