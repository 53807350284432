import React, { useEffect, useMemo, useState } from 'react';
import Image from 'next/image';
import { motion, useAnimation, Variants } from 'framer-motion';

import type { ImageProps } from 'next/image';

interface FadeInImageProps {
  duration?: number;
  delay?: number;
  isVisible?: boolean;
  once?: boolean;
  maxScale?: number;
}

const FadeInImage: React.FC<ImageProps & FadeInImageProps> = ({
  duration = 1,
  delay = 0,
  isVisible = false,
  once = false,
  maxScale = 1.025,
  ...rest
}) => {
  const [isImageLoaded, setImageLoaded] = useState(false);
  const [isDelayOver, setDelayOver] = useState(false);

  const controls = useAnimation();

  const variants: Variants = useMemo(
    () => ({
      visible: {
        opacity: 1,
        scale: 1,
      },
      hidden: {
        opacity: 0,
        scale: maxScale,
      },
    }),
    [maxScale],
  );

  useEffect(() => {
    if (!isVisible && !once) {
      controls.start('hidden');
    }
  }, [controls, isVisible, once]);

  useEffect(() => {
    if (isDelayOver && isImageLoaded && isVisible) {
      controls.start('visible');
    }
  }, [isImageLoaded, isDelayOver, controls, isVisible]);

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setDelayOver(true);
      }, delay * 1000);
    } else {
      setDelayOver(false);
    }
  }, [delay, isVisible]);

  const handleLoadingComplete = () => {
    setImageLoaded(true);
  };

  return (
    <motion.div
      initial="hidden"
      animate={controls}
      variants={variants}
      transition={{
        opacity: { duration: duration / 2 },
        default: {
          ease: 'easeOut',
          duration,
        },
      }}
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
      }}
    >
      <Image
        {...rest}
        onLoadingComplete={handleLoadingComplete}
        unoptimized
      />
    </motion.div>
  );
};

export default FadeInImage;
