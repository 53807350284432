import React, { SVGProps } from 'react';

const KakaoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={56}
    height={56}
    viewBox="0 0 73 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M73 36.5C73 56.6584 56.6584 73 36.5 73C16.3416 73 0 56.6584 0 36.5C0 16.3416 16.3416 0 36.5 0C56.6584 0 73 16.3416 73 36.5Z"
      fill="#525854"
    />
    <path
      d="M36 22C27.1631 22 20 27.6477 20 34.6154C20 39.12 22.9954 43.0769 27.5 45.3046C27.2554 46.1492 25.9246 50.7415 25.8723 51.1031C25.8723 51.1031 25.84 51.3738 26.0154 51.4769C26.0741 51.5064 26.1382 51.5237 26.2038 51.5277C26.2694 51.5316 26.3351 51.5222 26.3969 51.5C26.9015 51.4292 32.2431 47.6831 33.1662 47.0385C34.105 47.1694 35.052 47.2337 36 47.2308C44.8369 47.2308 52 41.5831 52 34.6154C52 27.6477 44.8369 22 36 22Z"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
);

export default KakaoIcon;
