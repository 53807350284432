import { SVGProps } from 'react';

const SvgBlimpSpace = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={357}
    height={78}
    viewBox="0 0 357 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M47.0778 38.4C51.5511 39.3533 55.1445 41.59 57.8578 45.11C60.5712 48.5567 61.9278 52.5167 61.9278 56.99C61.9278 63.4433 59.6545 68.5767 55.1078 72.39C50.6345 76.13 44.3645 78 36.2978 78H0.327813V0.779998H35.0878C42.9345 0.779998 49.0578 2.57666 53.4578 6.16999C57.9311 9.76333 60.1678 14.64 60.1678 20.8C60.1678 25.3467 58.9578 29.1233 56.5378 32.13C54.1911 35.1367 51.0378 37.2267 47.0778 38.4ZM19.1378 32.02H31.4578C34.5378 32.02 36.8845 31.36 38.4978 30.04C40.1845 28.6467 41.0278 26.63 41.0278 23.99C41.0278 21.35 40.1845 19.3333 38.4978 17.94C36.8845 16.5467 34.5378 15.85 31.4578 15.85H19.1378V32.02ZM32.9978 62.82C36.1511 62.82 38.5711 62.1233 40.2578 60.73C42.0178 59.2633 42.8978 57.1733 42.8978 54.46C42.8978 51.7467 41.9811 49.62 40.1478 48.08C38.3878 46.54 35.9311 45.77 32.7778 45.77H19.1378V62.82H32.9978ZM100.448 63.48H125.088V78H81.6376V0.779998H100.448V63.48ZM164.969 0.779998V78H146.159V0.779998H164.969ZM274.851 0.779998V78H256.041V31.69L238.771 78H223.591L206.211 31.58V78H187.401V0.779998H209.621L231.291 54.24L252.741 0.779998H274.851ZM356.027 25.64C356.027 30.1133 355.001 34.22 352.947 37.96C350.894 41.6267 347.741 44.5967 343.487 46.87C339.234 49.1433 333.954 50.28 327.647 50.28H315.987V78H297.177V0.779998H327.647C333.807 0.779998 339.014 1.84333 343.267 3.97C347.521 6.09666 350.711 9.02999 352.837 12.77C354.964 16.51 356.027 20.8 356.027 25.64ZM326.217 35.32C329.811 35.32 332.487 34.4767 334.247 32.79C336.007 31.1033 336.887 28.72 336.887 25.64C336.887 22.56 336.007 20.1767 334.247 18.49C332.487 16.8033 329.811 15.96 326.217 15.96H315.987V35.32H326.217Z"
      fill="black"
    />
  </svg>
);

export default SvgBlimpSpace;
