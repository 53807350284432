import { SVGProps } from 'react';

const SvgAirpod = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={27}
    height={24}
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.8867 3.0024C25.5751 1.26109 23.6574 -0.00205074 21.2701 0.000982012C21.0355 0.000982012 20.797 0.0126076 20.5538 0.0378805L20.5372 0.039397L20.5225 0.0419239C18.9505 0.289094 17.5554 1.13776 16.6198 2.641C15.6802 4.14221 15.1707 6.2525 15.1697 9.08661V21.9015C15.1702 22.7972 15.8955 23.523 16.7917 23.523H17.4731C18.3687 23.5225 19.0946 22.7972 19.0946 21.9015V10.926C19.8421 11.2429 20.6387 11.4026 21.4353 11.4026C23.5775 11.4026 25.7292 10.2997 26.9236 8.26828L27 8.13837V3.15101L26.8867 3.0024ZM21.4348 10.2926C20.523 10.2921 19.6223 10.05 18.8383 9.5486L17.9846 9.00321V21.9015C17.9841 22.1841 17.7551 22.413 17.4726 22.4136H16.7912C16.5086 22.413 16.2792 22.1841 16.2787 21.9015V9.08611C16.2776 6.38241 16.774 4.47682 17.559 3.23036C18.345 1.99097 19.3882 1.36016 20.6832 1.14029C20.8824 1.12007 21.0775 1.10996 21.2696 1.10996C23.1898 1.11299 24.7249 2.07084 25.8895 3.53667V7.81791C24.8962 9.41567 23.159 10.2926 21.4348 10.2926Z"
      fill="white"
    />
    <path
      d="M6.47746 0.0414535L6.4628 0.0389256L6.44562 0.036904C6.20249 0.011631 5.96341 5.44725e-06 5.72938 5.44725e-06C3.34159 -0.00302731 1.42438 1.26062 0.112717 3.00142L0 3.15003V8.13739L0.0763249 8.2673C1.27022 10.2987 3.42196 11.4017 5.5641 11.4017C6.3607 11.4017 7.1573 11.2424 7.90488 10.925V21.9016C7.90538 22.7972 8.63072 23.5231 9.5269 23.5231H10.2083C11.1039 23.5226 11.8298 22.7972 11.8298 21.9016V9.08614C11.8288 6.25203 11.3193 4.14174 10.3796 2.64053C9.444 1.13779 8.04944 0.289129 6.47746 0.0414535ZM10.7208 21.9016C10.7203 22.1841 10.4913 22.4131 10.2088 22.4136H9.5269C9.24435 22.4131 9.01487 22.1841 9.01436 21.9016V9.00274L8.16064 9.54864C7.37667 10.0501 6.47594 10.2922 5.5641 10.2927C3.83947 10.2927 2.10221 9.4157 1.10948 7.81794V3.53671C2.27406 2.07087 3.80864 1.11303 5.72888 1.10999C5.92045 1.10999 6.11555 1.1201 6.31521 1.14032C7.6107 1.3602 8.65397 1.99101 9.43945 3.2304C10.2244 4.47686 10.7213 6.38244 10.7198 9.08614V21.9016H10.7208Z"
      fill="white"
    />
  </svg>
);

export default SvgAirpod;
