import { SVGProps } from 'react';

const SvgArrowRight = ({ fill, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width={10}
    height={16}
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.41418 8L1.70708 15.7071L0.292864 14.2929L6.58576 8L0.292864 1.70711L1.70708 0.292892L9.41418 8Z"
      fill={fill ?? '#2D2828'}
    />
  </svg>
);

export default SvgArrowRight;
