import React, { useMemo } from 'react';
import {
  Box, Button, Flex, Heading, Text,
} from 'rebass/styled-components';

import FadeInUp from '@/components/animation/FadeInUp';
import usePager from '@/hooks/usePager';
import FadeInImage from '@/components/animation/FadeInImage';
import ContentsImage from '@/assets/images/contents.png';
import DesktopDeviceImage from '@/assets/images/device-image-desktop.png';
import QRCode from '@/assets/svgs/homepage-qr.svg';
import ResponsiveBuilder from '@/components/responsive/ResponsiveBuilder';

const DownloadSection: React.FC = () => {
  const { currentPagerIndex } = usePager();
  const isVisible = useMemo(() => currentPagerIndex === 3, [currentPagerIndex]);

  const openDownloadLink = () => {
    window.open('https://blmp.in/homepage', '_blank');
  };

  return (
    <Flex
      variant="box.section"
      as="section"
      sx={{ backgroundColor: 'black', position: 'relative' }}
      alignItems="center"
      justifyContent="space-between"
      flexDirection="column"
    >
      <Flex
        sx={{ height: '20vh', mt: ['6rem', '6rem', '4rem'], mb: ['4rem', '4rem', '2rem'] }}
        alignItems="flex-end"
      >
        <Heading
          variant="h4"
          as="h4"
          sx={{ textAlign: 'center' }}
        >
          <FadeInUp
            delay={1}
            isVisible={isVisible}
          >
            당장 떠나기 어렵다면,
          </FadeInUp>
          <FadeInUp
            delay={1.2}
            isVisible={isVisible}
          >
            당신의 방을 휴식하기 좋은 공간으로 바꿔보세요
          </FadeInUp>
        </Heading>
      </Flex>
      <Flex
        sx={{
          position: 'relative',
          overflowX: 'hidden',
          overflowY: 'visible',
          height: '100%',
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          sx={{
            width: '70rem',
            height: '27rem',
            opacity: 0.5,
          }}
          className="move-left"
        >
          <Box
            sx={{
              width: '140rem',
              height: '27rem',
            }}
          >
            <FadeInImage
              src={ContentsImage}
              isVisible
              once
              alt="contents"
              layout="fill"
              objectFit="contain"
              objectPosition="left"
              quality={100}
              loading="lazy"
              duration={2}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            bottom: '0',
          }}
        >
          <FadeInImage
            isVisible
            once
            src={DesktopDeviceImage}
            alt="contents"
            layout="fill"
            objectFit="contain"
            objectPosition="center"
            quality={100}
            loading="lazy"
            duration={2}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            background:
              'linear-gradient(-90deg,black 0%,transparent 10%,transparent 90%,black 100%)',
          }}
        />
      </Flex>
      <Flex
        sx={{
          width: '100%',
          bottom: '0',
          height: '18rem',
          backgroundColor: 'black',
        }}
        alignItems="center"
        justifyContent="center"
      >
        <ResponsiveBuilder
          small
          medium
        >
          <Button
            id="download-app"
            sx={{
              backgroundColor: '#4FA3D3',
              px: 'xxxl',
              py: 'l',
            }}
            variant="capsule"
            onClick={openDownloadLink}
          >
            지금 다운로드
          </Button>
        </ResponsiveBuilder>

        <ResponsiveBuilder large>
          <QRCode
            width="7.5rem"
            height="7.5rem"
          />
        </ResponsiveBuilder>
        <ResponsiveBuilder large>
          <Box sx={{ ml: 'l' }}>
            <Text
              variant="body1"
              sx={{ fontWeight: 'bold' }}
            >
              Scan to get the app
            </Text>
            <Text variant="body1">QR코드를 스캔해서 앱을 다운로드 받으세요</Text>
          </Box>
        </ResponsiveBuilder>
      </Flex>
    </Flex>
  );
};

export default DownloadSection;
