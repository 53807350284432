import React, { useMemo } from 'react';
import {
  Box, Flex, Heading, Text,
} from 'rebass/styled-components';
import { useTheme } from 'styled-components';

import InstagramPostSection from '../organisms/InstagramPostSection';
import InstagramPostMobileSection from '../organisms/InstagramPostMobileSection';

import FadeInUp from '@/components/animation/FadeInUp';
import usePager from '@/hooks/usePager';
import ResponsiveBuilder from '@/components/responsive/ResponsiveBuilder';
import { hexToRgb } from '@/styles/theme/landingColors';

const InstagramSection: React.FC = () => {
  const theme = useTheme();
  const { currentPagerIndex } = usePager();
  const isVisible = useMemo(() => currentPagerIndex === 2, [currentPagerIndex]);

  const goToInstagram = () => {
    window.open('https://instagram.com/blimp.space', '_blank');
  };

  return (
    <Flex
      as="section"
      variant="box.section"
      sx={{
        backgroundColor: 'beige.200',
        position: 'relative',
      }}
      alignItems="flex-start"
      justifyContent="center"
      flexDirection="column"
    >
      <Box
        sx={{
          px: ['1.5rem', '1.5rem', '12vw'],
          flex: '0 0 auto',
          height: ['25vh', '30vh', 'auto'],
          verticalAlign: 'bottom',
          display: ['flex', 'flex', 'block'],
          flexDirection: 'column',
          justifyContent: 'flex-end',
          pb: ['xl', 'xl', '0'],
          minHeight: 240,
        }}
      >
        <Heading
          variant="h3"
          as="h3"
          sx={{
            color: 'text.black',
            pt: 'xxxl',
          }}
        >
          <FadeInUp
            delay={1}
            isVisible={isVisible}
          >
            편히 쉴 수 있는 공간으로
          </FadeInUp>
          <FadeInUp
            delay={1.1}
            isVisible={isVisible}
          >
            여행을 떠나세요
          </FadeInUp>
        </Heading>
        <FadeInUp
          delay={1.5}
          isVisible={isVisible}
          overflow="visible"
        >
          <Text
            variant="body1"
            sx={{
              color: 'text.black',
              mt: 'l',
            }}
          >
            깊은 자연, 아늑한 스테이, 영감이 있는 예술 공간과 도심 속 숨은
            카페까지
          </Text>
        </FadeInUp>
      </Box>
      <ResponsiveBuilder large>
        <InstagramPostSection isVisible={isVisible} />
      </ResponsiveBuilder>
      <ResponsiveBuilder
        sx={{ width: '100%', flex: '1 1 auto', overflow: 'hidden' }}
        small
        medium
      >
        <InstagramPostMobileSection isVisible={isVisible} />
      </ResponsiveBuilder>
      <ResponsiveBuilder
        sx={{
          width: '100%',
          height: '8rem',
          background: `linear-gradient(180deg, rgba(${hexToRgb(
            theme.colors.beige[200],
          )}, 0), ${theme.colors.beige[200]} 3rem)`,
          position: 'absolute',
          pt: '3rem',
          bottom: 0,
          px: 'm',
          isolation: 'isolate',
        }}
        small
        medium
      >
        <Box
          sx={{
            width: '100%',
            height: '1px',
            backgroundColor: '#9E9E9E',
            mb: 'm',
          }}
        />
        <Text
          variant="caption1"
          sx={{
            textAlign: 'center',
            color: 'text.black',
            fontWeight: 'bold',
            width: '100%',
            cursor: 'pointer',
          }}
          onClick={goToInstagram}
        >
          더보기
        </Text>
      </ResponsiveBuilder>
    </Flex>
  );
};

export default InstagramSection;
