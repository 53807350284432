import React, { useEffect, useRef, useState } from 'react';
import ReactPageScroller from 'react-page-scroller';
import { AnimatePresence, motion } from 'framer-motion';
import Head from 'next/head';

import { PagerContext } from '@/hooks/usePager';
import HeaderSection from '@/components/IndexPage/sections/HeaderSection';
import ConceptSection from '@/components/IndexPage/sections/ConceptSection';
import Navbar from '@/components/IndexPage/organisms/Navbar';
import InstagramSection from '@/components/IndexPage/sections/InstagramSection';
import { landingTheme } from '@/styles/theme';
import DownloadSection from '@/components/IndexPage/sections/DownloadSection';
import Footer from '@/components/IndexPage/organisms/Footer';
import useBgm from '@/hooks/useBgm';
import gtm from '@/hooks/gtm';
import Layout from '@/components/layout/Layout';

const IndexPage: React.FC = () => {
  const [currentPagerIndex, setPagerIndex] = useState(0);
  const audioRef = useRef<HTMLAudioElement>();
  const bgmController = useBgm(audioRef);
  const pagerIndexHistory = useRef<Set<number>>(new Set());

  useEffect(() => {
    if (!pagerIndexHistory.current.has(currentPagerIndex)) {
      gtm.event({
        eventName: 'slider_changed',
        params: { index: `${currentPagerIndex + 1}` },
      });
      pagerIndexHistory.current.add(currentPagerIndex);
    }

    if (currentPagerIndex === 4) {
      const element = document.querySelector('div#__next > div > div');

      element.classList.add('footer-animation');
    } else {
      const element = document.querySelector('div#__next > div > div');

      element.classList.remove('footer-animation');
    }
  }, [currentPagerIndex]);

  return (
    <>
      <Head>
        <link
          rel="prefetch"
          as="audio"
          href="/praying-for-silence.m4a"
        />
        <meta
          name="naver-site-verification"
          content={process.env.NAVER_SITE_VERIFICATION_KEY}
        />
      </Head>
      <Layout theme={landingTheme}>
        <PagerContext.Provider value={{ currentPagerIndex, setPagerIndex }}>
          <ReactPageScroller
            onBeforePageScroll={setPagerIndex}
            animationTimer={850}
            renderAllPagesOnFirstRender
            containerHeight="100vh"
            animationTimerBuffer={500}
            transitionTimingFunction="cubic-bezier(.37,0,.26,1)"
            customPageNumber={currentPagerIndex}
          >
            <HeaderSection bgmController={bgmController} />
            <ConceptSection />
            <InstagramSection />
            <DownloadSection />
            <Footer />
          </ReactPageScroller>
          <AnimatePresence>
            {currentPagerIndex > 0 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Navbar
                  bgmController={bgmController}
                  color={
                    currentPagerIndex === 2
                      ? landingTheme.colors.text.black
                      : landingTheme.colors.text.white
                  }
                />
              </motion.div>
            )}
          </AnimatePresence>
          <audio
            loop
            ref={audioRef}
            src="/praying-for-silence.m4a"
          />
        </PagerContext.Provider>
      </Layout>
    </>
  );
};

export default IndexPage;
