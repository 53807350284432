import React from 'react';
import { Box, Text } from 'rebass/styled-components';
import Masonry from 'react-masonry-css';

import instagramPosts from 'instagram.featured';
import FadeInUp from '@/components/animation/FadeInUp';
import FadeInImage from '@/components/animation/FadeInImage';

const mobileInstagramPosts = [
  instagramPosts[0],
  instagramPosts[2],
  instagramPosts[1],
  ...instagramPosts.slice(3, 6),
];

const InstagramMobileSection: React.FC<{ isVisible: boolean }> = ({
  isVisible,
}) => {
  const openInstagram = (link: string) => () => {
    window.open(link, '_blank');
  };

  return (
    <Box
      sx={{
        '.mobile-insta-grid': {
          display: 'flex',
          ml: '-0.5rem',
          width: 'auto',
          p: '0 1rem',
        },
        '.mobile-insta-grid_column': {
          pl: '0.5rem',
          backgroundClip: 'padding-box',
        },
      }}
    >
      <Masonry
        breakpointCols={{ default: 3, 640: 2 }}
        className="mobile-insta-grid"
        columnClassName="mobile-insta-grid_column"
      >
        {mobileInstagramPosts.map((post, i) => (
          <FadeInUp
            key={post.location}
            delay={1.75 + i * 0.1}
            duration={1.5}
            isVisible={isVisible}
            overflow="visible"
            from={10}
            sx={{
              mb: 'xxl',
            }}
          >
            <Box
              as="figure"
              sx={{
                pt: `${post.ratio * 100}%`,
                position: 'relative',
                borderRadius: 10,
                overflow: 'hidden',
                cursor: 'pointer',
                isolation: 'isolate',
              }}
              onClick={() => window.open(post.link, '_blank')}
            >
              <Box
                className="instagram-card"
                data-insta-link={post.link}
                data-insta-title={post.location}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
                onClick={openInstagram(post.link)}
              >
                <FadeInImage
                  isVisible
                  once
                  src={post.thumbnail}
                  alt={post.location}
                  layout="fill"
                  objectFit="contain"
                  objectPosition="center top"
                  quality={80}
                  loading="lazy"
                  duration={2}
                />
              </Box>
            </Box>
            <Text
              variant="caption1"
              as="figcaption"
              sx={{
                color: 'text.black',
                mt: 'm',
                opacity: 0.8,
              }}
            >
              {post.location}
            </Text>
          </FadeInUp>
        ))}
      </Masonry>
    </Box>
  );
};

export default InstagramMobileSection;
