import { SVGProps } from 'react';

const SvgPlaystore = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={56}
    height={56}
    viewBox="0 0 73 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={36.5}
      cy={36.5}
      r={36.5}
      fill="#525854"
    />
    <path
      d="M50.9173 34.9578L27.5834 21.3295C26.8375 20.8935 25.9452 20.89 25.1959 21.3204C24.4472 21.7501 24 22.5225 24 23.3861V50.6146C24 51.4782 24.4472 52.2507 25.1959 52.6804C25.5674 52.8942 25.9746 53.0007 26.3819 53.0007C26.7948 53.0007 27.2076 52.8907 27.5834 52.672L50.9173 39.0423C51.6512 38.6133 52.0893 37.85 52.0893 37.0004C52.0893 36.1508 51.6512 35.3867 50.9173 34.9578ZM25.7524 50.4008V23.9119L38.3222 37.0466L25.7524 50.4008ZM39.5356 38.314L42.8176 41.7439L28.3586 50.1884L39.5356 38.314ZM27.732 23.4457L42.826 32.2618L39.5251 35.7688L27.732 23.4457ZM50.0333 37.5289L44.3737 40.8347L40.7385 37.0361L44.3786 33.1682L50.0333 36.4711C50.3074 36.631 50.3369 36.8945 50.3369 37.0004C50.3369 37.1062 50.3074 37.3691 50.0333 37.5289Z"
      fill="white"
    />
  </svg>
);

export default SvgPlaystore;
