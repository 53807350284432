import React, { useEffect, useState } from 'react';
import {
  createGlobalStyle,
  DefaultTheme,
  ThemeProvider,
} from 'styled-components';
import { DefaultSeoProps } from 'next-seo';
import Head from 'next/head';

import SEO from './SEO';

import { lightTheme, darkTheme } from '@/styles/theme';

const GlobalStyle = createGlobalStyle`
  html {
    background: ${({ theme }) => theme.colors.background};
  }
`;

const Layout: React.FC<{
  seo?: Partial<DefaultSeoProps>;
  scheme?: Record<string, unknown>;
  theme?: DefaultTheme;
  children: React.ReactNode;
}> = ({
  seo = {}, scheme, theme: preferredTheme, children,
}) => {
  const [theme, setTheme] = useState(preferredTheme);

  useEffect(() => {
    if (!preferredTheme) {
      if (window?.matchMedia('(prefers-color-scheme: dark)').matches) {
        setTheme(darkTheme);
      } else {
        setTheme(lightTheme);
      }

      window
        ?.matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (event) => {
          if (event.matches) {
            setTheme(darkTheme);
          } else {
            setTheme(lightTheme);
          }
        });
    }
  }, [preferredTheme]);

  return (
    <>
      <SEO config={seo} />
      <Head>
        <script
          id="schema"
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(
              scheme ?? {
                '@context': 'https://schema.org',
                '@type': 'Organization',
                url: 'https://blimp.space',
                logo: 'https://blimp.space/apple-icon.png',
                sameAs: ['https://www.instagram.com/blimp.official'],
              },
            ),
          }}
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <ThemeProvider theme={preferredTheme ?? theme ?? lightTheme}>
        <>{children}</>
        <GlobalStyle />
      </ThemeProvider>
    </>
  );
};

export default Layout;
