import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';

import BlimpLogo from '@/assets/svgs/blimp.svg';
import BlimpTextLogo from '@/assets/svgs/blimp-space.svg';
import InstagramIcon from '@/assets/svgs/instagram.svg';
import AppstoreIcon from '@/assets/svgs/appstore.svg';
import PlaystoreIcon from '@/assets/svgs/playstore.svg';
import ResponsiveBuilder from '@/components/responsive/ResponsiveBuilder';
import KakaoIcon from '@/assets/svgs/kakao.svg';

const Footer: React.FC = () => (
  <Flex
    sx={{
      height: '35rem',
      backgroundColor: 'green.700',
      px: 'xl',
      py: 'xxl',
      display: ['block', 'block', 'flex'],
    }}
  >
    <BlimpLogo
      width={40}
      height={40}
    />
    <Box sx={{ mt: 10, ml: 20 }}>
      <BlimpTextLogo
        height={20}
        width={190}
      />
      <ResponsiveBuilder
        small
        medium
      >
        <Box
          sx={{
            width: '100%',
            textAlign: 'center',
            svg: { mx: 5, mt: 'l', cursor: 'pointer' },
            display: 'flex',
          }}
        >
          <a
            href="https://instagram.com/blimp.space"
            target="_blank"
            rel="noreferrer"
            className="footer-button"
            data-footer-source="instagram"
          >
            <InstagramIcon
              className="no-click"
              data-footer-source="instagram"
            />
          </a>
          <a
            href="https://apps.apple.com/kr/app/%EB%B8%94%EB%A6%BC%ED%94%84-blimp/id1499816662"
            target="_blank"
            rel="noreferrer"
            className="footer-button"
            data-footer-source="appstore"
          >
            <AppstoreIcon className="no-click" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.scatterlab.peace"
            target="_blank"
            rel="noreferrer"
            className="footer-button"
            data-footer-source="playstore"
          >
            <PlaystoreIcon className="no-click" />
          </a>
          <a
            href="https://pf.kakao.com/_xexmkyb/chat"
            target="_blank"
            rel="noreferrer"
            className="footer-button"
            data-footer-source="kakao"
          >
            <KakaoIcon className="no-click" />
          </a>
        </Box>
      </ResponsiveBuilder>
      <Text
        variant="body1"
        sx={{ mt: 'xl' }}
      >
        주식회사 스캐터랩 | 대표 : 김종윤 | 사업자 등록 번호 : 215-87-57315
        <br />
        통신판매 신고번호 : 2021-서울성동-00664 | 이메일 : contact@blimp.space
        <br />
        주소 : 서울특별시 성동구 왕십리로 125, KD타워 901호, 902호 (04766)
        <br />
        <a
          href="https://link.blmp.in/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          이용 약관
        </a>
        {' '}
        |
        {' '}
        <a
          href="https://link.blmp.in/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          개인정보 처리 방침
        </a>
        <br />
        <br />
        Copyright @ 2021 BLIMP All rights reserved
      </Text>
    </Box>
    <ResponsiveBuilder large>
      <Box
        sx={{
          width: '100%',
          flex: '1 1 auto',
          textAlign: 'right',
          svg: { ml: 10, cursor: 'pointer' },
        }}
      >
        <a
          href="https://instagram.com/blimp.space"
          target="_blank"
          rel="noreferrer"
          className="footer-button"
          data-footer-source="instagram"
        >
          <InstagramIcon
            className="no-click"
            data-footer-source="instagram"
          />
        </a>
        <a
          href="https://apps.apple.com/kr/app/%EB%B8%94%EB%A6%BC%ED%94%84-blimp/id1499816662"
          target="_blank"
          rel="noreferrer"
          className="footer-button"
          data-footer-source="appstore"
        >
          <AppstoreIcon className="no-click" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.scatterlab.peace"
          target="_blank"
          rel="noreferrer"
          className="footer-button"
          data-footer-source="playstore"
        >
          <PlaystoreIcon className="no-click" />
        </a>
        <a
          href="https://pf.kakao.com/_xexmkyb/chat"
          target="_blank"
          rel="noreferrer"
          className="footer-button"
          data-footer-source="kakao"
        >
          <KakaoIcon className="no-click" />
        </a>
      </Box>
    </ResponsiveBuilder>
  </Flex>
);

export default Footer;
