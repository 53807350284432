import React, { useMemo } from 'react';
import {
  Box, Button, Text, Flex,
} from 'rebass/styled-components';
import { useRouter } from 'next/router';

import sectionBackground from '@/assets/images/section-1-bg.jpg';
import AirPodsIcon from '@/assets/svgs/airpod.svg';
import FadeInImage from '@/components/animation/FadeInImage';
import FadeInUp from '@/components/animation/FadeInUp';
import usePager from '@/hooks/usePager';
import BlimpTextLogo from '@/assets/svgs/blimp-space.svg';
import { BgmController } from '@/hooks/useBgm';

const HeaderSection: React.FC<{ bgmController: BgmController }> = ({
  bgmController,
}) => {
  const { currentPagerIndex, setPagerIndex } = usePager();
  const isVisible = useMemo(() => currentPagerIndex === 0, [currentPagerIndex]);
  const router = useRouter();

  const goToNextPage = () => {
    bgmController.unlockAndPlay();
    setPagerIndex(1);
  };
  const goToBlimpStore = () => {
    router.push('/store');
  };

  return (
    <Box
      variant="box.section"
      as="section"
      tabIndex={0}
      ref={(ref) => ref && ref.focus()}
    >
      <Box
        variant="box.background"
        backgroundColor="background"
      >
        <FadeInImage
          once
          isVisible={isVisible}
          src={sectionBackground}
          alt="배경 이미지"
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          sizes="100vw"
          quality={80}
          loading="eager"
          priority
          duration={2}
          delay={1.25}
        />
      </Box>
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{ height: '100%', p: 'xxl' }}
      >
        <Box />
        <Box
          sx={{
            mt: ['4rem', '6.5rem', '8rem'],
            textAlign: 'center',
            svg: { height: ['3rem', '4.5rem', '5rem'], width: '100%', mb: 'm' },
          }}
        >
          <FadeInUp
            from={80}
            isVisible={isVisible}
          >
            <Box
              sx={{
                path: {
                  fill: 'white',
                },
              }}
            >
              <BlimpTextLogo />
            </Box>
          </FadeInUp>
          <FadeInUp
            delay={0.75}
            isVisible={isVisible}
          >
            <Text
              variant="body1"
              sx={{
                textAlign: 'center',
                mt: 's',
                mb: ['l', 'xxl', 'xxxl'],
              }}
            >
              세상의 모든 안식처로 당신을 안내합니다
            </Text>
          </FadeInUp>
          <FadeInUp
            from={40}
            delay={1}
            overflow="visible"
            isVisible={isVisible}
          >
            <Button
              id="go-to-store"
              onClick={goToBlimpStore}
              variant="capsule"
              sx={{ mx: 'auto' }}
            >
              BLIMP 스토어 바로가기
            </Button>
          </FadeInUp>
          <FadeInUp
            from={40}
            delay={1}
            overflow="visible"
            isVisible={isVisible}
          >
            <Text
              variant="subtitle2"
              onClick={goToNextPage}
              sx={{
                p: 'm',
                cursor: 'pointer',
              }}
            >
              더 알아보기
            </Text>
          </FadeInUp>
        </Box>
        <Box
          sx={{
            zIndex: 1,
          }}
        >
          <FadeInUp
            from={80}
            delay={1.125}
            overflow="visible"
            isVisible={isVisible}
          >
            <Text
              variant="caption1"
              sx={{ textAlign: 'center', svg: { mb: 's' } }}
            >
              <AirPodsIcon
                width={26}
                height={26}
              />
              <br />
              재생되는 음악을 들으며 읽어주세요
            </Text>
          </FadeInUp>
        </Box>
      </Flex>
    </Box>
  );
};

export default HeaderSection;
