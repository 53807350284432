import atinmaruImage from '@/assets/images/instagram/atinmaru.jpg';
import jongmyoImage from '@/assets/images/instagram/jongmyo.jpg';
import eunbitImage from '@/assets/images/instagram/eunbit.jpg';
import yukbaekImage from '@/assets/images/instagram/yukbaek.jpg';
import byeonsanImage from '@/assets/images/instagram/byeonsan.jpg';
import kimchangyeolImage from '@/assets/images/instagram/kimchangyeol.jpg';

import type { StaticImageData } from 'next/dist/client/image';

interface InstagramPost {
  location: string;
  thumbnail: StaticImageData;
  link: string;
  ratio: number;
}

const ratio = ({ width, height }: { width: number; height: number }) => height / width;

const instagramPosts: InstagramPost[] = [
  {
    location: '아틴마루',
    thumbnail: atinmaruImage,
    link: 'https://www.instagram.com/p/CVg7d-MreY_/',
    ratio: ratio({ width: 4, height: 5 }),
  },
  {
    location: '종묘',
    thumbnail: jongmyoImage,
    link: 'https://www.instagram.com/p/CVScjGKg7kX/',
    ratio: ratio({ width: 14, height: 9 }),
  },
  {
    location: '육백마지기',
    thumbnail: yukbaekImage,
    link: 'https://www.instagram.com/p/CVe2EsPJNiQ/',
    ratio: ratio({ width: 4, height: 5 }),
  },
  {
    location: '온빛자연휴양림',
    thumbnail: eunbitImage,
    link: 'https://www.instagram.com/p/CV4lmxaFBqM/',
    ratio: ratio({ width: 4, height: 5 }),
  },
  {
    location: '변산마실길',
    thumbnail: byeonsanImage,
    link: 'https://www.instagram.com/p/CVnC97dvmdJ/',
    ratio: ratio({ width: 4, height: 5 }),
  },
  {
    location: '김창렬 미술관',
    thumbnail: kimchangyeolImage,
    link: 'https://www.instagram.com/p/CVXfOi6MIXM/',
    ratio: ratio({ width: 14, height: 9 }),
  },
];

export default instagramPosts;
