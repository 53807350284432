import React from 'react';
import { DefaultSeo, DefaultSeoProps } from 'next-seo';
import merge from 'deepmerge';

import seoConfig from './seoConfig';

const SEO: React.FC<{ config?: Partial<DefaultSeoProps> }> = ({
  config = {},
}) => (
  <DefaultSeo
    {...merge(seoConfig, config, { arrayMerge: (target, source) => source })}
  />
);

export default SEO;
