import type { DefaultSeoProps } from 'next-seo';

const isProduction = process.env.NODE_ENV === 'production';

const pageTitle = '블림프 (BLIMP)';
const pageDescription = '블림프의 전문 큐레이션으로 엄선된 공간, 문화, 브랜드를 경험하는 여행을 떠나 보세요. 깊은 자연, 아늑한 스테이, 영감으로 가득한 전시 ･ 공연 티켓, 도심 속 숨은 카페와 미식 공간까지 오아시스 같은 여행지를 발견해 보세요.';
const pageUrl = isProduction
  ? 'https://blimp.space'
  : 'https://dev-web.blimp.space';

const seoConfig: DefaultSeoProps = {
  titleTemplate: `%s | ${pageTitle}`,
  defaultTitle: pageTitle,
  noindex: !isProduction,
  nofollow: !isProduction,
  disableGooglebot: !isProduction,
  description: pageDescription,
  additionalMetaTags: [
    {
      httpEquiv: 'x-ua-compatible',
      content: 'IE=edge; chrome=1',
    },
    {
      name: 'viewport',
      content:
        'width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover',
    },
    {
      name: 'theme-color',
      content: '#000000',
    },
    {
      name: 'twitter:app:name:iphone',
      content: '블림프 (BLIMP)',
    },
    {
      name: 'twitter:app:id:iphone',
      content: '1499816662',
    },
    {
      name: 'twitter:app:name:googleplay',
      content: '블림프 (BLIMP) - 소리로 떠나는 휴식 여행',
    },
    {
      name: 'twitter:app:id:googleplay',
      content: 'com.scatterlab.peace',
    },
  ],
  twitter: {
    cardType: 'app',
    site: 'https://blimp.space',
  },
  facebook: {
    appId: '487524308824023',
  },
  openGraph: {
    type: 'website',
    description: pageDescription,
    site_name: pageTitle,
    images: [
      {
        url: `${pageUrl}/og-image.jpg`,
        width: 1200,
        height: 675,
        alt: 'BLIMP SPACE',
      },
    ],
  },
};

export default seoConfig;
