import React from 'react';

const VolumeIcon: React.FC<{ onClick: React.MouseEventHandler, className: string; }> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ transform: 'scale(0.75)' }}
  >
    <path
      d="M18.079 0.246216C17.6809 0.0489072 17.2353 -0.0323788 16.7932 0.0116551C16.3511 0.055689 15.9303 0.223265 15.579 0.495216L7.523 6.69522C7.45642 6.74616 7.37483 6.77359 7.291 6.77322H2.38C1.74937 6.77401 1.14478 7.02474 0.698668 7.47047C0.25256 7.9162 0.00132268 8.52059 0 9.15122V19.6992C0.000794337 20.3301 0.25183 20.9349 0.698024 21.3809C1.14422 21.8269 1.74912 22.0777 2.38 22.0782H7.623C7.70054 22.0785 7.77619 22.1022 7.84 22.1462L15.673 27.5692C16.03 27.8169 16.4481 27.9621 16.8818 27.9889C17.3155 28.0158 17.7482 27.9234 18.1331 27.7218C18.518 27.5201 18.8403 27.2169 19.0651 26.845C19.2898 26.4731 19.4084 26.0467 19.408 25.6122V2.38222C19.4096 1.93726 19.2858 1.50084 19.0508 1.12304C18.8157 0.745237 18.4789 0.441342 18.079 0.246216ZM17.408 25.6162C17.4086 25.6862 17.3898 25.755 17.3538 25.815C17.3177 25.875 17.2658 25.9239 17.2038 25.9563C17.1417 25.9887 17.0719 26.0033 17.0021 25.9985C16.9322 25.9937 16.8651 25.9697 16.808 25.9292L8.977 20.5052C8.57938 20.2294 8.10692 20.0818 7.623 20.0822H2.38C2.32984 20.0822 2.28018 20.0723 2.23388 20.053C2.18758 20.0337 2.14556 20.0055 2.11023 19.9699C2.07491 19.9343 2.04698 19.892 2.02806 19.8456C2.00914 19.7991 1.9996 19.7494 2 19.6992V9.15122C2 9.05034 2.04 8.95358 2.11124 8.88216C2.18247 8.81074 2.27913 8.77048 2.38 8.77022H7.289C7.81367 8.7696 8.32356 8.59636 8.74 8.27722L16.796 2.07722C16.8509 2.03106 16.9185 2.00262 16.99 1.99566C17.0614 1.9887 17.1332 2.00354 17.196 2.03822C17.2611 2.06785 17.316 2.116 17.3539 2.17664C17.3917 2.23728 17.4109 2.30774 17.409 2.37922L17.408 25.6162Z"
      fill="white"
    />
    <path
      id="wave-1"
      d="M22.5 9.99922C22.5 9.44693 22.9477 8.99922 23.5 8.99922C24.0523 8.99922 24.5 9.44693 24.5 9.99922V16.9992C24.5 17.5515 24.0523 17.9992 23.5 17.9992C22.9477 17.9992 22.5 17.5515 22.5 16.9992V9.99922Z"
      fill="white"
    />
    <path
      id="wave-2"
      d="M27 6.99922C27 6.44693 27.4477 5.99922 28 5.99922C28.5523 5.99922 29 6.44693 29 6.99922V19.9992C29 20.5515 28.5523 20.9992 28 20.9992C27.4477 20.9992 27 20.5515 27 19.9992V6.99922Z"
      fill="white"
    />
  </svg>
);

export default VolumeIcon;
