import React, { useMemo } from 'react';
import {
  Text, Flex, Heading, Box,
} from 'rebass/styled-components';

import FadeInUp from '@/components/animation/FadeInUp';
import usePager from '@/hooks/usePager';
import FadeInImage from '@/components/animation/FadeInImage';
import conceptBackgroundImage from '@/assets/images/concept-background.jpg';

const ConceptSection: React.FC = () => {
  const { currentPagerIndex } = usePager();
  const isVisible = useMemo(() => currentPagerIndex === 1, [currentPagerIndex]);

  return (
    <Flex
      variant="box.section"
      as="section"
      sx={{
        backgroundColor: 'green.800',
        px: ['l', 'l', '12vw'],
        pt: 'xl',
        position: 'relative',
      }}
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          opacity: 0.8,
          pl: '45vw',
          pt: 'xxxl',
          pb: 'xl',
        }}
      >
        <FadeInImage
          isVisible={isVisible}
          src={conceptBackgroundImage}
          alt="배경 이미지"
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          sizes="100vw"
          quality={80}
          loading="eager"
          priority
          duration={2}
          delay={1.25}
        />
      </Box>
      <Heading
        variant="h3"
        as="h3"
      >
        <FadeInUp
          delay={1}
          isVisible={isVisible}
        >
          &lsquo;오늘 참 잘 쉬었다&rsquo;고
        </FadeInUp>
        <FadeInUp
          delay={1.1}
          isVisible={isVisible}
        >
          생각한 적이 언제인가요?
        </FadeInUp>
      </Heading>
      <FadeInUp
        delay={1.5}
        isVisible={isVisible}
        overflow="visible"
        from={5}
      >
        <Text
          variant="body1"
          as="p"
          sx={{ mt: ['m', 'm', 'l'] }}
        >
          몸과 마음, 정신까지 회복되는 쉼
          <br />
          잡념이 사라지고 영감이 떠오르는 쉼
          <br />
          더 나은 내일을 준비하고 기대하게 하는 쉼
          <br />
          <br />
          아쉽게도 우리 일상의 환경 속에선
          <br />
          이런 휴식을 하기 어렵습니다.
          <br />
          일주일 중 단 하루라도 &lsquo;참 잘 쉰다&rsquo;면
          <br />
          남은 날을 살아갈 힘을 얻을 텐데요.
          <br />
          <br />
          하지만 당신이 있는 곳에서 조금만 벗어나도,
          <br />
          깊이 쉴 수 있는 공간을 찾을 수 있습니다.
        </Text>
      </FadeInUp>
    </Flex>
  );
};

export default ConceptSection;
