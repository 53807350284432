import React, { useCallback } from 'react';
import { Flex } from 'rebass/styled-components';

import VolumeIcon from './VolumeIcon';

import BlimpLogo from '@/assets/svgs/blimp.svg';
import BlimpTextLogo from '@/assets/svgs/blimp-space.svg';
import { BgmController } from '@/hooks/useBgm';
import usePager from '@/hooks/usePager';

const Navbar: React.FC<{ color: string; bgmController: BgmController }> = ({
  color,
  bgmController,
}) => {
  const { setPagerIndex } = usePager();

  const goToTop = () => {
    setPagerIndex(0);
  };

  const toggleAudioPlaying = useCallback(() => {
    if (bgmController.isPlaying) {
      bgmController.pause();
    } else {
      bgmController.unlockAndPlay();
    }
  }, [bgmController]);
  return (
    <Flex
      className={bgmController.isPlaying ? 'playing' : 'stopped'}
      alignItems="center"
      justifyContent="space-between"
      sx={{
        pl: 'calc(env(safe-area-inset-left) + 1.5rem)',
        pr: 'calc(env(safe-area-inset-right) + 1.5rem)',
        py: 'm',
        position: 'fixed',
        zIndex: 1,
        top: 0,
        left: 0,
        right: 0,
        path: {
          fill: color,
          transition: 'fill 0.3s ease-in-out',
        },
        '.text-logo': {
          cursor: 'pointer',
          height: ['1rem', '1rem', '1.25rem'],
        },
        '.symbol-logo, .volume-icon': {
          width: ['1.5rem', '1.5rem', '2rem'],
          height: ['1.5rem', '1.5rem', '2rem'],
          flex: '0 0 auto',
        },
      }}
    >
      <BlimpLogo
        onClick={goToTop}
        className="symbol-logo"
      />
      <BlimpTextLogo
        className="text-logo"
        onClick={goToTop}
      />
      <VolumeIcon
        onClick={toggleAudioPlaying}
        className="volume-icon"
      />
    </Flex>
  );
};

export default Navbar;
