import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTheme } from 'styled-components';

import instagramPosts from 'instagram.featured';
import FadeInImage from '@/components/animation/FadeInImage';
import ArrowRightIcon from '@/assets/svgs/arrow_right.svg';
import FadeInUp from '@/components/animation/FadeInUp';
import { hexToRgb } from '@/styles/theme/landingColors';

const InstagramPostSection: React.FC<{ isVisible: boolean }> = ({
  isVisible,
}) => {
  const theme = useTheme();
  const scrollSectionRef = useRef<HTMLElement>();
  const [isScrollEnded, setScrollEnded] = useState(false);

  useEffect(() => {
    const handleScrollEvent = (event) => {
      setScrollEnded(
        event.target.scrollLeft + event.target.offsetWidth
          > event.target.scrollWidth - 200,
      );
    };

    if (scrollSectionRef.current) {
      scrollSectionRef.current.addEventListener('scroll', handleScrollEvent);
    }
    const element = scrollSectionRef.current;
    return () => {
      element.removeEventListener('scroll', handleScrollEvent);
    };
  }, [scrollSectionRef]);

  const openInstagram = (link: string) => () => {
    window.open(link, '_blank');
  };

  const scrollRight = useCallback(() => {
    if (scrollSectionRef.current) {
      scrollSectionRef.current.scrollBy({
        top: 0,
        left: window.innerWidth * 0.25,
        behavior: 'smooth',
      });
    }
  }, []);
  return (
    <Box sx={{ width: '100vw', position: 'relative' }}>
      <Flex
        alignItems="center"
        justifyContent="center"
        sx={{
          zIndex: 1,
          right: 0,
          top: 0,
          bottom: 0,
          width: '25vw',
          background: `linear-gradient(90deg, rgba(${hexToRgb(
            theme.colors.beige[200],
          )}, 0), ${theme.colors.beige[200]})`,
          position: 'absolute',
          transition: 'opacity 0.25s ease-in-out',
          pointerEvents: 'none',
          opacity: isScrollEnded ? 0 : 1,
        }}
      >
        <FadeInUp
          delay={2}
          isVisible={isVisible}
          overflow="visible"
          from={10}
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '3.5rem',
              height: '3.5rem',
              background: 'white',
              borderRadius: '50%',
              boxShadow: '0 0 0.5rem 0.25rem rgba(0,0,0,0.1)',
              cursor: 'pointer',
              transition: 'transform 0.25s ease-in-out',
              ':hover': {
                transform: 'scale(1.125)',
              },
              pointerEvents: 'auto',
            }}
            onClick={scrollRight}
          >
            <ArrowRightIcon />
          </Flex>
        </FadeInUp>
      </Flex>
      <Box
        sx={{
          width: '100%',
          overflowX: 'scroll',
          overflowY: 'visible',
          pt: 'xxl',
          position: 'relative',
        }}
        ref={scrollSectionRef}
      >
        <Flex
          sx={{
            height: '100%',
            '> div, > div > div': { height: '100%' },
          }}
        >
          {instagramPosts.map((post, i) => {
            const gtmPayload = {
              className: 'instagram-card',
              'data-insta-link': post.link,
              'data-insta-title': post.location,
            };
            return (
              <FadeInUp
                key={post.location}
                delay={1.75 + i * 0.1}
                duration={1.5}
                isVisible={isVisible}
                overflow="visible"
                from={10}
                sx={{
                  flex: '0 0 auto',
                }}
              >
                <Box
                  as="figure"
                  sx={{
                    overflow: 'hidden',
                    cursor: 'pointer',
                    flex: '0 0 auto',
                    mr: 'm',
                    transition: 'transform 0.25s cubic-bezier(.37,0,.26,1)',
                    ':hover': {
                      transform: 'translateY(-6px)',
                    },
                    ...(i === instagramPosts.length - 1
                      ? {
                        mr: '12vw',
                      }
                      : {}),
                    ...(i === 0
                      ? {
                        ml: '12vw',
                      }
                      : {}),
                  }}
                  onClick={openInstagram(post.link)}
                >
                  <Box
                    sx={{
                      width: '22vw',
                      height: `${22 * post.ratio}vw`,
                      borderRadius: 10,
                      isolation: 'isolate',
                      overflow: 'hidden',
                    }}
                  >
                    <FadeInImage
                      {...gtmPayload}
                      isVisible
                      once
                      src={post.thumbnail}
                      alt={post.location}
                      layout="fill"
                      objectFit="contain"
                      objectPosition="center top"
                      quality={80}
                      loading="lazy"
                      duration={2}
                    />
                  </Box>
                  <Text
                    {...gtmPayload}
                    variant="caption1"
                    as="figcaption"
                    sx={{
                      color: 'text.black',
                      mt: 'm',
                      opacity: 0.8,
                    }}
                  >
                    {post.location}
                  </Text>
                </Box>
              </FadeInUp>
            );
          })}
        </Flex>
      </Box>
    </Box>
  );
};

export default InstagramPostSection;
