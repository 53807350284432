import { createContext, useContext } from 'react';

interface PagerContextState {
  currentPagerIndex: number;
  setPagerIndex:(index: number) => void;
}

export const PagerContext = createContext<PagerContextState>({
  currentPagerIndex: 0,
  setPagerIndex: () => {},
});

function usePager(): PagerContextState {
  return useContext(PagerContext);
}

export default usePager;
